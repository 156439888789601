import React, { useEffect, useState } from "react";
import Container from "../components/common/Container";
import {
  useGetLoyaltyPoints,
  useGetProfile,
  usePostRedeem,
} from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import Text from "../components/common/Text";
import { getQueryParams, isLoggedIn } from "../utils/helpers";
import moment from "moment";
import { useLanguage } from "../utils/Hooks";
import NoContent from "../components/common/NoContent";
import VoucherModal from "../components/Home/VoucherModal";

function LoyaltyPoints() {
  const navigate = useNavigate();

  const { data: profile } = useGetProfile(isLoggedIn());
  const { userLanguage } = useLanguage();
  const { table, tenant, branch } = useParams();

  const [IsRedeemed, setIsRedeemed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [openModal, setOpenModal] = useState(false);

  const { isLoading, data } = useGetLoyaltyPoints({ is_redeemed: IsRedeemed });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleOpenVocuher = () => {
    setOpenModal(true);
  };
  const orders = data?.results || [];
  const totalPages = data?.total_pages || 1;

  const handleNavigation = async (basePath) => {
    const queryString = await getQueryParams();
    navigate(basePath + (queryString ? `?${queryString}` : ""));
  };

  const {
    isLoading: isPosting,
    mutate: postRedeem,
    isSuccess: isSuccessAdd,
  } = usePostRedeem();

  const handleSubmit = () => {
    postRedeem();
  };

  useEffect(() => {
    if (isSuccessAdd)
      handleNavigation(
        table
          ? `/${tenant}/${branch}/${table}${"/coupons/"}`
          : `/${tenant}/${branch}/coupons/`
      );
  }, [isSuccessAdd]);

  return (
    <Container
      title="Loyalty Points"
      isLoading={isLoading}
      viewBottomTab={true}
    >
      <div className="row mb-2">
        <div className="col-12 mb-2">
          <div className="card mb-3 bg-color-system">
            <div className="card-body p-3">
              {/* <div className="d-flex justify-content-center align-items-center">
                  {profile?.avatar && isLoggedIn() ? (
                    <img
                      src={profile?.avatar}
                      alt=""
                      className="rounded-circle btn-light"
                      style={{ objectFit: "cover", height: 75, width: 75 }}
                    />
                  ) : (
                    <i className="bi bi-person-circle"></i>
                  )}
                
                  <div className="d-flex flex-column mx-2">
                    <h6 className="mt-2 mb-0 text-white">{profile?.name}</h6>
                    <h6 className="mb-0 text-white">{profile?.email}</h6>
                  </div>
                </div> */}
              <h5 className="mb-4 text-white">
                <Text title="Loyalty Points" />
              </h5>

              <div className="w-100 d-flex justify-content-between my-1 mt-2">
                {/* <h5 className="mb-0 text-white">
                    <Text title="Available Points" />
                  </h5> */}
                <strong className="text-white d-flex align-items-center">
                  <div className="circle_point mr-5 ml-5">
                    <div className="half gold"></div>
                    <div className="half gray"></div>
                    {/* <span className="cup">🏆</span>{" "} */}
                    <i class="bi bi-trophy-fill font-large"></i>
                  </div>
                  <div className="" style={{ marginInline: "20px" }}>
                    <h2 className="d-flex">
                      {data?.available_points}
                      <h2 style={{ marginInline: "10px" }}>
                        <Text title="Point" />
                      </h2>
                    </h2>
                    <p className="mb-0 text-white fw-normal">
                      <Text title="Available Points Value" />
                    </p>
                    <p className="text-white fw-normal">
                      {data?.available_points_value}
                    </p>
                  </div>
                </strong>
              </div>
              <hr className="dashed-hr" />

              <div className="row mt-2">
                <div className="col-12 col-sm-6">
                  <button
                    className="btn btn-primary shadow-sm btn-lg w-100 btn-rounded"
                    style={{
                      backgroundColor:
                        data?.available_points > 0 ? "transparent" : "gray",
                      border: "1px solid white",
                      cursor:
                        data?.available_points > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={handleSubmit}
                    disabled={isPosting || data?.available_points <= 0}
                  >
                    <h6 className="text-white">
                      <Text title={"Redeem Points"} />
                    </h6>
                  </button>
                </div>
                <div className="col-12 col-sm-6">
                  <button
                    className="btn btn-primary shadow-sm btn-lg w-100 btn-rounded use_vouchers"
                    onClick={handleOpenVocuher}
                    disabled={isPosting}
                  >
                    <h6 className="text-white">
                      <Text title={"use_vouchers"} />
                    </h6>
                  </button>
                </div>
              </div>
              <VoucherModal openModal={openModal} setOpenModal={setOpenModal} />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-evenly mb-4">
          <button
            style={{ height: "50px", border: "0" }}
            type="button"
            className={`w-100 btn btn-${
              !IsRedeemed ? "default fw-bold" : "light fw-bold"
            } py-2`}
            onClick={() => setIsRedeemed(false)}
          >
            <Text title="Not Redeemed" />
          </button>
          <span className="mx-1" />
          <button
            style={{ height: "50px", border: "0" }}
            type="button"
            className={`w-100 btn btn-${
              IsRedeemed ? "default fw-bold" : "light fw-bold"
            } py-2`}
            onClick={() => {
              setIsRedeemed(true);
            }}
          >
            <Text title="Redeemed" />
          </button>
        </div>

        {orders?.length ? (
          orders.map((order) => (
            <div className="col-12" key={order.id}>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col align-self-center">
                      <div className="d-flex justify-content-between align-items-center my-1">
                        <h5 className="mb-0">{order.order}</h5>
                        <span
                          className="size-12 text-end"
                          style={{ maxWidth: "60%" }}
                        >
                          {moment(order.create_at)
                            .locale(userLanguage)
                            .format("DD-MM-YYYY - hh:mm a")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-1">
                        <h5 className="size-12 text-muted">
                          {order.points} <Text title="Points" />
                        </h5>
                        <span className="size-12">
                          <span
                            className={`${
                              order?.is_redeemed
                                ? "text-success"
                                : "text-danger"
                            }`}
                          >
                            {order?.is_redeemed ? (
                              <Text title={"Redeemed"} />
                            ) : (
                              <Text title={"Not Redeemed"} />
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoContent />
        )}

        {totalPages > 1 && (
          <nav className="mt-3">
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        )}

        <div className="d-flex flex-column justify-content-between">
          {!!data?.redeemed_points && IsRedeemed && (
            <div className="w-100 d-flex justify-content-between my-1">
              <h5 className="mb-0">
                <Text title="Redeemed Points" />
              </h5>
              <strong>{data?.redeemed_points}</strong>
            </div>
          )}
          {!!data?.redeemed_points && IsRedeemed && (
            <div className="w-100 d-flex justify-content-between my-1">
              <h5 className="mb-0">
                <Text title="Redeemed Points Value" />
              </h5>
              <strong>{data?.redeemed_points_value}</strong>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default LoyaltyPoints;
