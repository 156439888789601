import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useCart, useLanguage } from "../../utils/Hooks";
import Text from "../common/Text";
import { useVouchers } from "../../utils/API";

function VoucherModal({ openModal, setOpenModal }) {
  const [formData, setFormData] = useState({
    voucher_code: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const { mutate, error, isSuccess } = useVouchers();

  const handleVouchers = () => {
    mutate(formData);
  };
  useEffect(() => {
    if (isSuccess) {
      setFormData({ voucher_code: "" });
      setOpenModal(false);
    }
  }, [isSuccess]);

  return (
    <Modal
      show={openModal}
      centered
      onHide={() => setOpenModal(false)}
      animation={false}
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title>
          <Text title="use_vouchers" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Form.Label>
          <Text title="use_vouchers" />
        </Form.Label>
        <Form.Control
          type="text"
          name="voucher_code"
          value={formData.voucher_code}
          onChange={handleChange}
        />
      </Modal.Body>
      {error?.response?.data?.responseMessage?.map((message) => (
        <p className="text-center text-danger" key={message}>
          {message}
        </p>
      ))}
      <Modal.Footer className="bg-light">
        <Button variant="primary" onClick={() => handleVouchers(false)}>
          <Text title="Confirm" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VoucherModal;
