import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/common/Container";
import { useCart, useTheme } from "../utils/Hooks";
import { useCheckDistance, useCheckout, useGetAddresses, useGetAreas, useGetProfile, usePostOnlineOrder } from "../utils/API";
import { Button, Form } from "react-bootstrap";
import Text from "../components/common/Text";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import MapModal from "../components/common/MapModal";
import moment from "moment";
import { getQueryParams, isLoggedIn, isWithinRadius } from "../utils/helpers";

function PlaceOrder() {
    const navigate = useNavigate();
    const { table, tenant, branch } = useParams();
    const { cart, useClearCart } = useCart();
    const { PaymentMethods, style } = useTheme();

    const { data: profile } = useGetProfile(isLoggedIn());

    const { isLoading: isLoadingAddresses, data: Addresses } = useGetAddresses(isLoggedIn());

    useEffect(() => {
        if (Addresses?.results?.length) setAddress_id(Addresses?.results?.[0]?.id);
    }, [Addresses]);

    const [initialize, setInitialize] = useState(false);
    const [address_id, setAddress_id] = useState(false);

    useEffect(() => {
        setInitialize(true);
    }, []);

    useEffect(() => {
        if (profile && initialize) {
            setItem({ ...Item, name: profile?.name || "", email: profile?.email || "", mobile: profile?.mobile || "" });
            setInitialize(false);
        }
    }, [profile, initialize]);

    const { data: Areas } = useGetAreas({ tenant, branch });

    const coupon_text = localStorage.getItem("Coupon");

    useEffect(() => {
        if (PaymentMethods?.length) {
            setItem({ ...Item, payment_method: PaymentMethods[0]?.id });
        }
    }, [PaymentMethods]);

    const [Item, setItem] = useState({
        type: "3",
        payment_method: "",
        area: null,
        note: null,
        is_pickup_time: false,
        pickup_time: null,
        delivery_fees: null,
    });

    const { mutate, isLoading, data } = usePostOnlineOrder();

    useEffect(() => {
        const response = data?.data?.responseData;
        if (response) {
            const { payment_link, encrypted_id } = response;
            localStorage.removeItem("Coupon");
            if (payment_link) {
                useClearCart();
                window.location.href = payment_link;
            } else if (encrypted_id) {
                useClearCart();
                navigate(`/${tenant}/${branch}/order-details/${encrypted_id}`);
            }
        }
    }, [data]);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            if (!cart?.length) return toast.error(<Text title="Your cart is empty" />);
            else {
                const obj = {
                    // tenant_slug: tenant,
                    branch_slug: branch,
                    type: Item.type,
                    payment_method: Item?.payment_method,
                    pickup_time: Item?.pickup_time,
                    items: cart?.map((item) => ({
                        product_id: item?.id,
                        qty: item?.Counter,
                        modifiers: item?.Modifiers?.map((modifier) => ({
                            modifier_id: modifier?.id,
                            qty: item?.Counter,
                        })),
                    })),
                    customer: {
                        name: Item.name,
                        mobile: Item.mobile,
                        email: Item.email,
                    },
                    ...(profile?.id ? { registered_customer: profile?.id } : {}),
                    ...(Item.type === "3"
                        ? {
                              address: address_id
                                  ? address_id
                                  : {
                                        area: Item.area,
                                        address: address,
                                        latitude: coordinates.lat,
                                        longitude: coordinates.lng,
                                        note: Item.note,
                                        address_details: Item.address_details,
                                    },
                          }
                        : {}),
                    ...(coupon_text ? { coupon_text } : {}),
                };
                mutate(obj);
            }
        }

        setValidated(true);
    };

    const [mapModal, setMapModal] = useState(false);
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

    const { mutate: checkDistance, data: results } = useCheckDistance();

    useEffect(() => {
        if ((coordinates.lat || coordinates.lng) && address) {
            const area = Areas?.results?.find((item) => item?.id === parseInt(Item?.area));
            checkDistance({
                tenant_slug: tenant,
                branch_slug: branch,
                area_id: area?.id,
                latitude: coordinates.lat,
                longitude: coordinates.lng,
            });
        }
    }, [coordinates, address]);

    const [Results, setResults] = useState(false);

    useEffect(() => {
        if (results?.data?.responseData) setResults(results?.data?.responseData);
    }, [results?.data?.responseData]);

    const { mutate: checkout, data: checkoutResults } = useCheckout();

    useEffect(() => {
        if (cart.length) {
            const area = Areas?.results?.find((item) => item?.id === parseInt(Item?.area));
            checkout({
                tenant_slug: tenant,
                branch_slug: branch,
                ...(coupon_text ? { coupon_text } : {}),
                ...(area?.id ? { area_id: area?.id } : {}),
                ...(coordinates.lat ? { latitude: coordinates.lat } : {}),
                ...(coordinates.lng ? { longitude: coordinates.lng } : {}),
                items: cart?.map((item) => ({
                    product_id: item?.id,
                    qty: item?.Counter,
                    modifiers: item?.Modifiers?.map((modifier) => ({
                        modifier_id: modifier?.id,
                        qty: item?.Counter,
                    })),
                })),
            });
        }
    }, [address, cart.length]);

    const [CheckoutResults, setCheckoutResults] = useState(false);

    useEffect(() => {
        if (checkoutResults?.data?.responseData) setCheckoutResults(checkoutResults?.data?.responseData);
    }, [checkoutResults?.data?.responseData]);

    const handleSelect = async (selectedAddress) => {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        const area = Areas?.results?.find((item) => item?.id === parseInt(Item?.area));
        const check = isWithinRadius(latLng, area?.custom_shape_coordinates);

        if (check) {
            setItem({
                ...Item,
                address: selectedAddress,
                coordinates: latLng,
            });

            setAddress(selectedAddress);
            setCoordinates(latLng);
        } else {
            setAddress("");
            toast.error(<Text title="Selected location out of delivery area" />);
        }
    };

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <Container title={"Place Order"}>
            <div className="mt-4 d-flex justify-content-center">
                <h4>
                    <Text title="Place Order" />
                </h4>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="card my-3">
                    <div className="card-body">
                        <Form.Group className="mb-3 mt-1">
                            <div className="d-flex justify-content-evenly">
                                <button style={{ height: "50px", border: "0" }} type="button" className={`w-100 btn btn-${Item?.type === "3" ? "default fw-bold" : "light fw-bold"} py-2`} onClick={(e) => setItem({ ...Item, type: "3" })}>
                                    <i className="bi bi-truck mx-1"></i>
                                    <Text title="Delivery" />
                                </button>
                                <span className="mx-1"></span>
                                <button
                                    style={{ height: "50px", border: "0" }}
                                    type="button"
                                    className={`w-100 btn btn-${Item?.type === "2" ? "default fw-bold" : "light fw-bold"} py-2`}
                                    onClick={(e) => {
                                        setItem({ ...Item, type: "2" });
                                        setAddress("");
                                        setCoordinates({ lat: 0, lng: 0 });
                                    }}
                                >
                                    <i className="bi bi-box-seam mx-1"></i>
                                    <Text title="Pickup" />
                                </button>
                            </div>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label className="fw-bold" htmlFor="payment_method">
                                <Text title="payment_method" />
                            </Form.Label>
                            {PaymentMethods?.map((method) => (
                                <div key={method?.id} className="d-flex justify-content-start">
                                    <Form.Check className="mx-2" checked={Item?.payment_method === method?.id} type={"radio"} id={method?.id} onChange={(e) => setItem({ ...Item, payment_method: method?.id })} />
                                    <Form.Label htmlFor={method?.id}>
                                        <Text title={method?.name} />
                                    </Form.Label>
                                </div>
                            ))}
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label className="fw-bold" htmlFor="is_pickup_time">
                                <Text title={Item?.type === "3" ? "delivery_time" : "pickup_time"} />
                            </Form.Label>

                            <div className="d-flex justify-content-start">
                                <Form.Check
                                    className="mx-2"
                                    checked={Item?.is_pickup_time === false}
                                    type={"radio"}
                                    id="asap"
                                    onChange={(e) =>
                                        setItem({
                                            ...Item,
                                            is_pickup_time: false,
                                            pickup_time: null,
                                        })
                                    }
                                />
                                <Form.Label htmlFor="asap">
                                    <Text title="asap" />
                                </Form.Label>
                            </div>

                            <div className="d-flex justify-content-start">
                                <Form.Check
                                    className="mx-2"
                                    checked={Item?.is_pickup_time === true}
                                    type={"radio"}
                                    id="schaduale"
                                    onChange={(e) =>
                                        setItem({
                                            ...Item,
                                            is_pickup_time: true,
                                            pickup_time: moment(new Date().toISOString().slice(0, 16)).locale("en").format("YYYY-MM-DD HH:mm"),
                                        })
                                    }
                                />
                                <Form.Label htmlFor="schaduale">
                                    <Text title="Schedule_time" />
                                </Form.Label>
                            </div>
                        </Form.Group>
                        {Item?.is_pickup_time && (
                            <Form.Group>
                                <Form.Control
                                    type="datetime-local"
                                    id="pickup_time"
                                    value={Item?.pickup_time}
                                    min={new Date().toISOString().slice(0, 16)}
                                    onChange={(e) =>
                                        setItem({
                                            ...Item,
                                            pickup_time: moment(e.target.value).locale("en").format("YYYY-MM-DD HH:mm"),
                                        })
                                    }
                                />
                            </Form.Group>
                        )}
                    </div>
                </div>
                <div className="card my-3">
                    <div className="card-body">
                        <Form.Group className="my-3">
                            <Form.Label className="fw-bold" htmlFor="name">
                                <Text title="Name" />
                            </Form.Label>
                            <Form.Control type="text" id="name" required value={Item?.name} onChange={(e) => setItem({ ...Item, name: e.target.value })} />
                            <Form.Control.Feedback type="invalid">
                                <Text title="Name is required" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="my-3">
                            <Form.Label className="fw-bold" htmlFor="mobile">
                                <Text title="Mobile" />
                            </Form.Label>
                            <Form.Control type="text" id="mobile" required value={Item?.mobile} onChange={(e) => setItem({ ...Item, mobile: e.target.value })} />
                            <Form.Control.Feedback type="invalid">
                                <Text title="Mobile is required" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="my-3">
                            <Form.Label className="fw-bold" htmlFor="email">
                                <Text title="Email" />
                            </Form.Label>
                            <Form.Control type="text" id="email" value={Item?.email} onChange={(e) => setItem({ ...Item, email: e.target.value })} />
                            <Form.Control.Feedback type="invalid">
                                <Text title="Email is required" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                {Item?.type === "3" &&
                    (isLoggedIn() ? (
                        <>
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <Form.Label className="fw-bold mb-0">
                                    <Text title="Select Address" />
                                </Form.Label>
                                <Button variant="outline-default" className="mx-1" size="sm" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/my-addresses/${"new"}` : `/${tenant}/${branch}/my-addresses/${"new"}`)}>
                                    <Text title="add" />
                                </Button>
                            </div>
                            {Addresses?.results?.map((address) => (
                                <div key={address?.id} className={`card mb-3 ${address?.id === address_id ? "border-primary" : ""}`} role="button" onClick={() => setAddress_id(address?.id)} style={{ border: "1px solid transparent" }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <div className="d-flex align-items-center">
                                                <input type="radio" id={`address-${address.id}`} name="address" value={address.id} checked={address.id === address_id} onChange={() => setAddress_id(address?.id)} className="me-2" />
                                                <h5 className="mx-2">{address?.address_name}</h5>
                                            </div>
                                            <Button variant="outline-default" size="sm" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/my-addresses/${address.id}` : `/${tenant}/${branch}/my-addresses/${address.id}`)}>
                                                <Text title="Edit" />
                                            </Button>
                                        </div>
                                        <p>{address?.address || address?.address_details}</p>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <div className="card my-3">
                                <div className="card-body">
                                    <Form.Group className="my-3">
                                        <Form.Label className="fw-bold" htmlFor="area">
                                            <Text title="Area" />
                                        </Form.Label>
                                        <Form.Select style={{ borderRadius: "50px" }} id="area" required value={Item?.area || ""} onChange={(e) => setItem({ ...Item, area: e.target.value })}>
                                            <option key={0} value={""} disabled>
                                                <Text title="Area" />
                                            </option>
                                            {Areas?.results?.map((area) => (
                                                <option key={area?.id} value={`${area?.id}`}>
                                                    {area?.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    {Item?.area && style?.use_address_map && <MapModal setCoordinates={setCoordinates} setAddress={setAddress} coordinates={coordinates} isWithinRadius={isWithinRadius} area={Areas?.results?.find((item) => item?.id === parseInt(Item?.area))} />}
                                    <Form.Group className="my-3">
                                        {style?.use_address_map && (
                                            <>
                                                <Form.Label className="d-flex justify-content-between align-items-center" htmlFor="address">
                                                    <p className="fw-bold m-0">
                                                        <Text title="Address" />
                                                    </p>
                                                </Form.Label>
                                                <PlacesAutocomplete value={address} onChange={(newAddress) => setAddress(newAddress)} onSelect={handleSelect}>
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <Form.Control
                                                                {...getInputProps({
                                                                    disabled: !Item?.area,
                                                                    className: "form-control",
                                                                    id: "address",
                                                                    required: style?.use_address_map,
                                                                })}
                                                            />
                                                            <div>
                                                                {loading ? <div>Loading...</div> : null}
                                                                {suggestions.map((suggestion) => (
                                                                    <div {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                                                                        {suggestion.description}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </>
                                        )}

                                        <Form.Control.Feedback type="invalid">
                                            <Text title="Address is required" />
                                        </Form.Control.Feedback>

                                        <Form.Group className="my-3">
                                            <Form.Label htmlFor="address_details">
                                                <p className="fw-bold m-0">
                                                    <Text title="address_details" />
                                                </p>
                                            </Form.Label>
                                            <Form.Control type="text" id="address_details" value={Item?.address_details} onChange={(e) => setItem({ ...Item, address_details: e.target.value })} required={!style?.use_address_map} />
                                            <Form.Text muted as="small" style={{ fontSize: 12 }}>
                                                <Text title="Building No / Level No / Apartment No" />
                                            </Form.Text>
                                        </Form.Group>
                                    </Form.Group>

                                    {address && Results?.distance && (
                                        <div className="row my-2">
                                            <div className="col">
                                                <p>
                                                    <Text title={"distance"} />
                                                </p>
                                            </div>
                                            <div className="col-auto fw-bold">{Results?.distance}</div>
                                        </div>
                                    )}

                                    <Form.Group className="my-3">
                                        <Form.Label className="fw-bold" htmlFor="note">
                                            <Text title="notes" />
                                        </Form.Label>
                                        <Form.Control type="text" id="note" value={Item?.note} onChange={(e) => setItem({ ...Item, note: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                        </>
                    ))}

                <div className="card my-3">
                    <div className="card-body">
                        <Form.Label className="fw-bold" htmlFor="note">
                            <Text title="Summary" />
                        </Form.Label>
                        {!!CheckoutResults?.length &&
                            CheckoutResults?.map(
                                (item) =>
                                    item?.value && (
                                        <div className="row my-2" key={item?.label}>
                                            <div className="col">
                                                <p>
                                                    <Text title={item?.label} />
                                                </p>
                                            </div>
                                            <div className="col-auto fw-bold">{item?.value}</div>
                                        </div>
                                    )
                            )}
                    </div>
                </div>

                <div className="my-5">
                    <button className="btn btn-default w-100 btn-rounded py-3" disabled={isLoading}>
                        <h5>{isLoading ? <Text title="loading..." /> : <Text title="Confirm" />}</h5>
                    </button>
                </div>
            </Form>
            <div className="clearfix"></div>
        </Container>
    );
}

export default PlaceOrder;
