import { createBrowserRouter } from "react-router-dom";

import Landing from "../pages/Landing";
import Home from "../pages/Home";
import Styles from "../pages/Styles";
import Product from "../pages/Product";
import Cart from "../pages/Cart";
import ContactUs from "../pages/ContactUs";
import TermsConditions from "../pages/TermsConditions";
import AboutUs from "../pages/AboutUs";
import Reservation from "../pages/Reservation";
import TrackOrder from "../pages/TrackOrder";
import PlaceOrder from "../pages/PlaceOrder";

import Splash from "../pages/Splash";
import P404 from "../pages/404";
import Error from "../pages/Error";

import RecipeDetails from "../pages/RecipeDetails";
import Games from "../pages/Games";
import Plays from "../pages/plays";
import Offers from "../pages/Offers";
import Menja from "../pages/Games/MENJA/index";
import Coloren from "../pages/Games/Coloren";
import ExpirePage from "../pages/ExpirePage";
import Recipe from "../pages/Recipe";
import CatgoriesPage from "../pages/Categories";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import SignUp from "../pages/SignUp";
import ForgetPassword from "../pages/ForgetPassword";
import ForgetPasswordConfirm from "../pages/ForgetPasswordConfirm";
import MyOrders from "../pages/MyOrders";
import OrderDetails from "../pages/OrderDetails";
import MyAddresses from "../pages/MyAddresses";
import AddressDetails from "../pages/AddressDetails";
import LoyaltyPoints from "../pages/LoyaltyPoints";
import Coupons from "../pages/Coupons";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Splash />,
    },
    {
        path: "/:tenant",
        element: <Landing />,
    },
    {
        path: "/:tenant/:branch/landing",
        element: <Landing />,
    },
    {
        path: "/:tenant/:branch",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/styles",
        element: <Styles />,
        errorElement: <Error path="/styles" />,
    },
    {
        path: "/:tenant/:branch/cart",
        element: <Cart />,
        errorElement: <Error path="/cart" />,
    },
    {
        path: "/:tenant/:branch/:table/cart",
        element: <Cart />,
        errorElement: <Error path="/cart" />,
    },
    {
        path: "/:tenant/:branch/login",
        element: <Login />,
        errorElement: <Error path="/login" />,
    },
    {
        path: "/:tenant/:branch/:table/login",
        element: <Login />,
        errorElement: <Error path="/login" />,
    },
    {
        path: "/:tenant/:branch/profile",
        element: <Profile />,
        errorElement: <Error path="/profile" />,
    },
    {
        path: "/:tenant/:branch/:table/profile",
        element: <Profile />,
        errorElement: <Error path="/profile" />,
    },
    {
        path: "/:tenant/:branch/my-orders",
        element: <MyOrders />,
        errorElement: <Error path="/my-orders" />,
    },
    {
        path: "/:tenant/:branch/:table/my-orders",
        element: <MyOrders />,
        errorElement: <Error path="/my-orders" />,
    },
    {
        path: "/:tenant/:branch/coupons",
        element: <Coupons />,
        errorElement: <Error path="/coupons" />,
    },
    {
        path: "/:tenant/:branch/:table/coupons",
        element: <Coupons />,
        errorElement: <Error path="/coupons" />,
    },
    {
        path: "/:tenant/:branch/my-orders/:id",
        element: <OrderDetails />,
        errorElement: <Error path="/my-orders/:id" />,
    },
    {
        path: "/:tenant/:branch/:table/my-orders/:id",
        element: <OrderDetails />,
        errorElement: <Error path="/my-orders/:id" />,
    },
    {
        path: "/:tenant/:branch/my-addresses",
        element: <MyAddresses />,
        errorElement: <Error path="/my-addresses" />,
    },
    {
        path: "/:tenant/:branch/:table/my-addresses",
        element: <MyAddresses />,
        errorElement: <Error path="/my-addresses" />,
    },
    {
        path: "/:tenant/:branch/my-addresses/:id",
        element: <AddressDetails />,
        errorElement: <Error path="/my-addresses/:id" />,
    },
    {
        path: "/:tenant/:branch/:table/my-addresses/:id",
        element: <AddressDetails />,
        errorElement: <Error path="/my-addresses/:id" />,
    },
    {
        path: "/:tenant/:branch/loyalty-points",
        element: <LoyaltyPoints />,
        errorElement: <Error path="/loyalty-points" />,
    },
    {
        path: "/:tenant/:branch/:table/loyalty-points",
        element: <LoyaltyPoints />,
        errorElement: <Error path="/loyalty-points" />,
    },
    {
        path: "/:tenant/:branch/register",
        element: <SignUp />,
        errorElement: <Error path="/register" />,
    },
    {
        path: "/:tenant/:branch/:table/register",
        element: <SignUp />,
        errorElement: <Error path="/register" />,
    },
    {
        path: "/:tenant/:branch/forgot-password",
        element: <ForgetPassword />,
        errorElement: <Error path="/forgot-password" />,
    },
    {
        path: "/:tenant/:branch/:table/forgot-password",
        element: <ForgetPassword />,
        errorElement: <Error path="/forgot-password" />,
    },
    {
        path: "/:tenant/:branch/reset-password-confirm",
        element: <ForgetPasswordConfirm />,
        errorElement: <Error path="/reset-password-confirm" />,
    },
    {
        path: "/:tenant/:branch/:table/reset-password-confirm",
        element: <ForgetPasswordConfirm />,
        errorElement: <Error path="/reset-password-confirm" />,
    },
    {
        path: "/:tenant/:branch/product/:id",
        element: <Product />,
        errorElement: <Error path="/product/:id" />,
    },
    {
        path: "/:tenant/:branch/:table/product/:id",
        element: <Product />,
        errorElement: <Error path="/product/:id" />,
    },
    {
        path: "/:tenant/:branch/contactUs",
        element: <ContactUs />,
    },
    {
        path: "/:tenant/:branch/:table/contactUs",
        element: <ContactUs />,
    },
    {
        path: "/:tenant/:branch/termsConditions",
        element: <TermsConditions />,
    },
    {
        path: "/:tenant/:branch/:table/termsConditions",
        element: <TermsConditions />,
    },
    {
        path: "/:tenant/:branch/aboutUs",
        element: <AboutUs />,
    },
    {
        path: "/:tenant/:branch/:table/aboutUs",
        element: <AboutUs />,
    },
    {
        path: "/:tenant/:branch/reservation",
        element: <Reservation />,
    },
    {
        path: "/:tenant/:branch/place-order",
        element: <PlaceOrder />,
    },
    {
        path: "/:tenant/:branch/order-details/:id",
        element: <TrackOrder />,
    },
    {
        path: "/:tenant/:branch/plays",
        element: <Plays />,
    },
    {
        path: "/:tenant/:branch/:table/plays",
        element: <Plays />,
    },
    {
        path: "/:tenant/:branch/offers",
        element: <Offers />,
    },
    {
        path: "/:tenant/:branch/:table/offers",
        element: <Offers />,
    },
    {
        path: "/:tenant/:branch/recipes",
        element: <Recipe />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table/recipes",
        element: <Recipe />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/recipes/:id",
        element: <CatgoriesPage />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table/recipes/:id",
        element: <CatgoriesPage />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/recipes/:category/:id",
        element: <RecipeDetails />,
        errorElement: <Error />,
    },
    {
        path: "/:tenant/:branch/:table/recipes/:category/:id",
        element: <RecipeDetails />,
        errorElement: <Error />,
    },
    {
        path: "/flappy",
        element: <Games />,
    },
    {
        path: "/coloren",
        element: <Coloren />,
    },
    {
        path: "/menja",
        element: <Menja />,
    },
    {
        path: "/expired",
        element: <ExpirePage />,
    },
    {
        path: "/error",
        element: <P404 />,
    },
    {
        path: "*",
        element: <P404 />,
    },
]);
export default router;
