import React, { useState } from "react";
import Container from "../components/common/Container";
import { useGetCoupons } from "../utils/API";
import Text from "../components/common/Text";
import moment from "moment";
import { useLanguage } from "../utils/Hooks";
import NoContent from "../components/common/NoContent";

function Coupons() {
    const { userLanguage } = useLanguage();
    const [currentPage, setCurrentPage] = useState(1);

    const [is_valid, setIs_valid] = useState(true);
    const { isLoading, data } = useGetCoupons({ is_valid });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const coupons = data?.results || [];

    const totalPages = data?.total_pages || 1;

    return (
        <Container title="Coupons" isLoading={isLoading} viewBottomTab={true}>
            <div className="row mb-2">
                <h2 className="mb-4">
                    <Text title="Coupons" />
                </h2>
                <div className="d-flex justify-content-evenly mb-4">
                    <button style={{ height: "50px", border: "0" }} type="button" className={`w-100 btn btn-${is_valid ? "default fw-bold" : "light fw-bold"} py-2`} onClick={() => setIs_valid(true)}>
                        <Text title="Valid" />
                    </button>
                    <span className="mx-1" />
                    <button
                        style={{ height: "50px", border: "0" }}
                        type="button"
                        className={`w-100 btn btn-${!is_valid ? "default fw-bold" : "light fw-bold"} py-2`}
                        onClick={() => {
                            setIs_valid(false);
                        }}
                    >
                        <Text title="inValid" />
                    </button>
                </div>
                {coupons?.length ? (
                    coupons.map((coupon) => (
                        <div className="col-12" key={coupon.id}>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col align-self-center">
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <small className="text-muted size-12">
                                                    <Text title={"Coupon"} />
                                                </small>
                                                <small className="text-muted size-12">{coupon.code}</small>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <small className="text-muted size-12">
                                                    <Text title={"discount"} />
                                                </small>
                                                <strong className="size-12 text-end" style={{ maxWidth: "60%" }}>
                                                    {coupon.discount}
                                                </strong>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <small className="text-muted size-12">
                                                    <Text title={"start_date"} />
                                                </small>
                                                <span className="size-12 text-end" style={{ maxWidth: "60%" }}>
                                                    {moment(coupon.start_date).locale(userLanguage).format("DD-MM-YYYY - hh:mm a")}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <small className="text-muted size-12">
                                                    <Text title={"end_date"} />
                                                </small>
                                                <span className="size-12 text-end" style={{ maxWidth: "60%" }}>
                                                    {moment(coupon.end_date).locale(userLanguage).format("DD-MM-YYYY - hh:mm a")}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <strong className="size-12">
                                                    <Text title={"Status"} />
                                                </strong>
                                                <span className="size-12">
                                                    <span className={`${coupon?.is_valid ? "text-success" : "text-danger"}`}>{coupon?.is_valid ? <Text title={"Valid"} /> : <Text title={"inValid"} />}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <NoContent />
                )}
                {totalPages > 1 && (
                    <nav className="mt-3">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </Container>
    );
}

export default Coupons;
