import React, { useEffect, useState } from "react";
import Container from "../components/common/Container";
import { useGetAddresses, useGetAreas, useDeleteAddress } from "../utils/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import Text from "../components/common/Text";
import { getQueryParams } from "../utils/helpers";
import { useLanguage } from "../utils/Hooks";
import NoContent from "../components/common/NoContent";
import { Button, Modal } from "react-bootstrap";

function MyAddresses() {
    const navigate = useNavigate();
    const { userLanguage } = useLanguage();
    const { table, tenant, branch } = useParams();
    const { isLoading, data } = useGetAddresses();
    const { mutate: deleteAddress, isLoading: isLoadingDelete, isSuccess } = useDeleteAddress();

    useEffect(() => {
        if (isSuccess) {
            setShowModal(false);
            setAddressToDelete(null);
        }
    }, [isSuccess]);

    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [addressToDelete, setAddressToDelete] = useState(null);

    const { data: Areas } = useGetAreas({ tenant, branch });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const addresses = data?.results || [];
    const totalPages = data?.total_pages || 1;

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    const confirmDelete = (addressId) => {
        setAddressToDelete(addressId);
        setShowModal(true);
    };

    const handleDelete = () => {
        if (addressToDelete) {
            deleteAddress(addressToDelete);
        }
    };

    return (
        <Container title="My Addresses" isLoading={isLoading} viewBottomTab={true}>
            <div className="row mb-2">
                <h2 className="mb-4 d-flex justify-content-between align-items-center">
                    <Text title="My Addresses" />
                    <Button variant={"outline-default"} onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/my-addresses/new` : `/${tenant}/${branch}/my-addresses/new`)}>
                        <Text title="add" />
                    </Button>
                </h2>
                {addresses?.length ? (
                    addresses.map((address) => (
                        <div className="col-12" key={address.id}>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col align-self-center">
                                            <div className="d-flex justify-content-between align-items-center my-2">
                                                <h5 className="size-12 mb-0">{address.address_name}</h5>
                                                <h5 className="size-12 mb-0">{Areas?.results?.find((area) => area.id === address.area)?.name}</h5>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center my-1">
                                                <h5 className="text-muted size-12 mb-0">{address.note}</h5>
                                                <h5 className="text-muted size-12 mb-0">{address.address_details}</h5>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <strong className="size-12">{address.address}</strong>
                                            </div>
                                        </div>
                                        <div className="col-12 align-self-center d-flex justify-content-between align-items-center mt-3">
                                            <Button variant="danger" size="sm" onClick={() => confirmDelete(address.id)}>
                                                <Text title="Remove" />
                                            </Button>
                                            <Link to="#" onClick={() => handleNavigation(table ? `/${tenant}/${branch}/${table}/my-addresses/${address.id}` : `/${tenant}/${branch}/my-addresses/${address.id}`)} className="size-14">
                                                <Text title="Details" />
                                                <i className={`bi bi-chevron-${userLanguage === "en" ? "right" : "left"}`}></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <NoContent />
                )}
                {totalPages > 1 && (
                    <nav className="mt-3">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>

            {/* Confirmation Modal */}
            <Modal show={showModal} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Text title="Confirm Remove" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Text title="Are you sure you want to delete this address?" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        <Text title="Cancel" />
                    </Button>
                    <Button variant="danger" onClick={handleDelete} disabled={isLoadingDelete}>
                        <Text title="Remove" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default MyAddresses;
