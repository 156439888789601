import React, { useEffect, useLayoutEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "../../utils/Hooks";
import { Title } from "../common/Text";
import "./SubMenuSwiper.css";

function SubMenuSwiper({ subCategories = [], setSubSelectedCategory, selectedSubCategory }) {
    const { style } = useTheme();
    const [Direction, setDirection] = useState(style["direction-mode"]);
    const [swiper, setSwiper] = useState(style["direction-mode"]);

    useLayoutEffect(() => {
        if (style["direction-mode"] !== Direction) {
            swiper.changeLanguageDirection(style["direction-mode"]);
            setDirection(style["direction-mode"]);
        }
    }, [style, Direction, swiper]);

    useEffect(() => {
        if (swiper?.width && Direction) {
            swiper.changeLanguageDirection(style["direction-mode"]);
        }
    }, [swiper, Direction]);

    return (
        <div className="row mb-4">
            <div className="col-12 px-2 my-1">
                <Swiper onSwiper={(swiper) => setSwiper(swiper)} scrollbar={{ draggable: true }} spaceBetween={5} slidesPerView={"auto"}>
                    {subCategories?.map((subCategory, index) => (
                        <SwiperSlide key={index}>
                            <div
                                role={"button"}
                                className={selectedSubCategory === index ? "card text-center bg-theme text-white m-1 font-weight-600" : "card text-center m-1 text-muted"}
                                style={{
                                    margin: "auto",
                                    borderRadius: "50px",
                                }}
                                onClick={() => {
                                    setSubSelectedCategory(index);
                                    localStorage.setItem("selectedSubCategory", index);
                                }}
                            >
                                <div className="card-body p-1 d-flex align-items-center text-wrap">
                                    <img alt="" className="avatar avatar-40 rounded-20 coverimg rounded-sub-img" src={subCategory?.is_image_url ? subCategory?.image_url : subCategory?.image} />
                                    <p className="text-center size-13 px-3">
                                        <small>
                                            <Title en={subCategory?.title_en} ar={subCategory?.title_ar} />
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default SubMenuSwiper;
