import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTheme } from "../../utils/Hooks";
import splash from "../../assets/img/default-desktop-bg.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Autoplay } from "swiper";
import { useNavigate, useParams } from "react-router-dom";
import { getQueryParams } from "../../utils/helpers";

SwiperCore.use([Autoplay]);

function HomeSlider({ removeBg }) {
    const { style, Ads } = useTheme();
    const { tenant, branch, table } = useParams();

    const navigate = useNavigate();

    const toProduct = async (id) => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/product/${id}` : `/${tenant}/${branch}/product/${id}`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    const [Direction, setDirection] = useState(style["direction-mode"]);
    const [swiper, setSwiper] = useState(false);

    useLayoutEffect(() => {
        if (style["direction-mode"] !== Direction) {
            swiper.changeLanguageDirection(style["direction-mode"]);
            setDirection(style["direction-mode"]);
        }
    }, [style, Direction, swiper]);

    useEffect(() => {
        if (swiper?.width && Direction) {
            swiper.changeLanguageDirection(style["direction-mode"]);
        }
    }, [swiper, Direction]);

    return (
        <Swiper autoplay={{ delay: 3000 }} onSwiper={(swiper) => setSwiper(swiper)} slidesPerView={"auto"} style={{ height: "fit-content" }}>
            {!removeBg && (
                <SwiperSlide className="w-100">
                    <div className={style?.is_overlay_active ? "image-overlay" : "image-default"}>
                        <img src={style?.desktop_image || splash} alt="splash" className="splash-img" />
                    </div>
                </SwiperSlide>
            )}
            {Ads?.filter((item) => item?.show_in !== "3" && item?.type !== "video").map((item, index) => (
                <SwiperSlide key={index + 1} className="w-100">
                    <div className={"image-default"}>
                        <img src={item?.image} alt="splash" className="splash-img" onClick={() => toProduct(item?.linked_product?.id)} role="button" />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default HomeSlider;
