import React, { useEffect, useState } from "react";
import Container from "../components/common/Container";
import Text from "../components/common/Text";
import { Form } from "react-bootstrap";
import { useGetAddressDetails, usePostAddress, usePatchAddress, useGetAreas } from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../utils/Hooks";
import { getQueryParams, isWithinRadius } from "../utils/helpers";
import MapModal from "../components/common/MapModal";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";

function AddressDetails() {
    const { style } = useTheme();
    const navigate = useNavigate();
    const { table, tenant, branch, id } = useParams();
    const isNew = id === "new";

    const { data: Areas } = useGetAreas({ tenant, branch });

    const { data: addressData, isLoading: isLoadingAddress } = useGetAddressDetails(id);
    const { isLoading: isPosting, mutate: postAddress, isSuccess: isSuccessAdd } = usePostAddress();
    const { isLoading: isUpdating, mutate: patchAddress, isSuccess: isSuccessUpdate } = usePatchAddress();

    const handleNavigation = async (basePath) => {
        const queryString = await getQueryParams();
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    useEffect(() => {
        if (isSuccessAdd || isSuccessUpdate) navigate(-1);
        // handleNavigation(table ? `/${tenant}/${branch}/${table}${"/my-addresses/"}` : `/${tenant}/${branch}/my-addresses/`);
    }, [isSuccessAdd, isSuccessUpdate]);

    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

    const [Item, setItem] = useState({
        address_name: "",
        area: "",
    });

    const [validated, setValidated] = useState(false);

    const [errors, setErrors] = useState({
        address_name: false,
        area: false,
    });

    // Pre-fill data if editing
    useEffect(() => {
        if (!isNew && addressData) {
            setItem({
                ...addressData,
            });

            setAddress(addressData?.address || "");
            setCoordinates({ lat: addressData?.lat || 0, lng: addressData?.lng || 0 });
        }
    }, [addressData, isNew]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setItem((prev) => ({ ...prev, [id]: value }));
        setErrors((prev) => ({ ...prev, [id]: false }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            if (isNew) {
                postAddress({ ...Item, lat: coordinates.lat, lng: coordinates.lng, address: address });
            } else {
                patchAddress({ id, ...Item, lat: coordinates.lat, lng: coordinates.lng, address: address });
            }
        }
        setValidated(true);
    };

    const handleSelect = async (selectedAddress) => {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        const area = Areas?.results?.find((item) => item?.id === parseInt(Item?.area));
        const check = isWithinRadius(latLng, area?.custom_shape_coordinates);

        if (check) {
            setItem({
                ...Item,
                address: selectedAddress,
                coordinates: latLng,
            });

            setAddress(selectedAddress);
            setCoordinates(latLng);
        } else {
            setAddress("");
            toast.error(<Text title="Selected location out of delivery area" />);
        }
    };

    return (
        <Container title={isNew ? "Add Address" : "Edit Address"} isLoading={isLoadingAddress} viewBottomTab={true}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-11 mx-auto py-4">
                        <h2 className="mb-4">
                            <Text title={isNew ? "Add Address" : "Edit Address"} />
                        </h2>

                        <Form.Group className="my-3">
                            <Form.Label className="fw-bold" htmlFor="address_name">
                                <Text title="Address Name" />
                            </Form.Label>
                            <Form.Control type="text" id="address_name" isInvalid={errors.address_name} value={Item.address_name} onChange={handleChange} placeholder="" required />
                        </Form.Group>

                        <Form.Group className="my-3">
                            <Form.Label className="fw-bold" htmlFor="area">
                                <Text title="Area" />
                            </Form.Label>
                            <Form.Select style={{ borderRadius: "50px" }} id="area" required value={Item?.area || ""} onChange={(e) => setItem({ ...Item, area: e.target.value })}>
                                <option key={0} value={""} disabled>
                                    <Text title="Area" />
                                </option>
                                {Areas?.results?.map((area) => (
                                    <option key={area?.id} value={`${area?.id}`}>
                                        {area?.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {Item?.area && style?.use_address_map && <MapModal setCoordinates={setCoordinates} setAddress={setAddress} coordinates={coordinates} isWithinRadius={isWithinRadius} area={Areas?.results?.find((item) => item?.id === parseInt(Item?.area))} />}
                        <Form.Group className="my-3">
                            {style?.use_address_map && (
                                <>
                                    <Form.Label className="d-flex justify-content-between align-items-center" htmlFor="address">
                                        <p className="fw-bold m-0">
                                            <Text title="Address" />
                                        </p>
                                    </Form.Label>
                                    <PlacesAutocomplete value={address} onChange={(newAddress) => setAddress(newAddress)} onSelect={handleSelect}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <Form.Control
                                                    {...getInputProps({
                                                        disabled: !Item?.area,
                                                        className: "form-control",
                                                        id: "address",
                                                        required: style?.use_address_map,
                                                    })}
                                                />
                                                <div>
                                                    {loading ? (
                                                        <div>
                                                            <Text title="Loading..." />
                                                        </div>
                                                    ) : null}
                                                    {suggestions.map((suggestion) => (
                                                        <div {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                                                            {suggestion.description}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </>
                            )}

                            <Form.Control.Feedback type="invalid">
                                <Text title="Address is required" />
                            </Form.Control.Feedback>

                            <Form.Group className="my-3">
                                <Form.Label className="fw-bold" htmlFor="address_details">
                                    <Text title="address_details" />
                                </Form.Label>
                                <Form.Control type="text" id="address_details" value={Item?.address_details} onChange={(e) => setItem({ ...Item, address_details: e.target.value })} required={!style?.use_address_map} />
                                <Form.Text muted as="small" style={{ fontSize: 12 }}>
                                    <Text title="Building No / Level No / Apartment No" />
                                </Form.Text>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group className="my-3">
                            <Form.Label className="fw-bold" htmlFor="note">
                                <Text title="notes" />
                            </Form.Label>
                            <Form.Control type="text" id="note" value={Item?.note} onChange={(e) => setItem({ ...Item, note: e.target.value })} />
                        </Form.Group>

                        {/* Submit Button */}
                        <div className="d-grid">
                            <button type="submit" className="btn btn-default btn-lg btn-rounded shadow-sm" disabled={isPosting || isUpdating}>
                                <h6>{isPosting || isUpdating ? <Text title="Saving..." /> : <Text title={isNew ? "Add Address" : "Edit Address"} />}</h6>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default AddressDetails;
